import { ColDef, ColGroupDef } from '@ag-grid-community/core'
import { TypedDocumentNode } from '@apollo/client'

import {
  BadRequest,
  BadRequestFragment,
  Connected,
  Customizable,
  Documented,
  EntityPayload,
  Forbidden,
  ForbiddenFragment,
  Maybe,
  MessageGrouped,
  MetricValue,
  MetricValueInput,
  NotFound,
  NotFoundFragment,
  Scalars,
  SortOrder,
} from '@graphql/server/typescript'

import CONNECTION_COLORS from '@constants/connection'
import {
  METRIC_AREAS,
  METRIC_DURATIONS,
  METRIC_INVALIDS,
  METRIC_DISTANCES,
  METRIC_NULLABLES,
  METRIC_VOLUMES,
  METRIC_WEIGHTS,
  METRIC_WEIGHTS_COMP,
} from '@constants/metric'
import { ColumnConfig } from '@modules/TableView/hooks/useSheetState/types'

/* -------------------------------------------------------------------------- */
/*                                  Utilities                                 */
/* -------------------------------------------------------------------------- */

/**
 * https://stackoverflow.com/a/71101740/13904709
 */
export type Merge<A, B> = {
  [key in keyof A]: key extends keyof B ? B[key] : A[key]
} & B

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

export type KeysOf<T> = (keyof T)[]
/**
 * @description Check that type T3 is a value of type T1[T2]
 */
export type CheckIsValueOf<T1, T2 extends keyof T1, T3> = T1[T2] extends T3 ? T1[T2] : never

export type UnMaybe<T> = {
  [Prop in keyof T]: T[Prop] extends Maybe<infer T> ? T : T[Prop]
}
export type CreateMaybe<T> = {
  [P in keyof T]: P extends `__${string}` ? T[P] : T[P] | null
}
export type Full<T> = {
  [P in keyof T]-?: T[P]
}
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
export type DeepPartial<T> = T extends object
  ? T extends { __typename: any }
    ? { __typename: T['__typename'] } & {
        [P in Exclude<keyof T, '__typename'>]?: DeepPartial<T[P]>
      }
    : { [P in keyof T]?: DeepPartial<T[P]> }
  : T

export type WithEmptyString<T> = T | ''

export type CreateWithEmptyString<T> = { [Property in keyof T]: WithEmptyString<T[Property]> }

export type ExtractFound<T> = Extract<
  T,
  Exclude<T, UniversalBadRequest | UniversalForbidden | UniversalNotFound | null | undefined>
>

export type ToDistArray<Type> = Type extends any ? Type[] : never

/**
 * Evaluates to `true` if `T` is `any`. `false` otherwise.
 * (c) https://stackoverflow.com/a/68633327/5290447
 */
type IsAny<T> = unknown extends T ? ([keyof T] extends [never] ? false : true) : false

export type PathImpl<S, Key extends keyof S> = Key extends string
  ? S[Key] extends Payload<any>
    ? IsAny<ExtractFound<S[Key]>> extends true
      ? never
      : ExtractFound<S[Key]> extends Record<string, any> | any[]
      ?
          | `${Key}.${PathImpl<
              ExtractFound<S[Key]>,
              Exclude<keyof ExtractFound<S[Key]>, keyof any[]>
            > &
              string}`
          | `${Key}.${Exclude<keyof ExtractFound<S[Key]>, keyof any[]> & string}`
      : never
    : IsAny<S[Key]> extends true
    ? never
    : S[Key] extends Record<string, any> | any[]
    ?
        | `${Key}.${PathImpl<S[Key], Exclude<keyof S[Key], keyof any[]>> & string}`
        | `${Key}.${Exclude<keyof S[Key], keyof any[]> & string}`
    : never
  : never

export type ArrayPathImpl<S> = S extends Array<infer U>
  ? `${number}` | `${number}.${Path<U>}`
  : PathImpl<S, keyof S>

export type PathImpl2<S> = ArrayPathImpl<S> | keyof S

export type Path<S> = S extends any[]
  ? ArrayPathImpl<S>
  : S extends Payload<any>
  ? keyof ExtractFound<S> extends string | number
    ? PathImpl2<ExtractFound<S>> extends infer P
      ? P extends string | keyof ExtractFound<S>
        ? P
        : keyof ExtractFound<S>
      : keyof ExtractFound<S>
    : never
  : keyof S extends string | number
  ? PathImpl2<S> extends infer P
    ? P extends string | keyof S
      ? P
      : keyof S
    : keyof S
  : never

export type PathValue<S, P extends Path<S>> = S extends Array<infer U>
  ? P extends `${number}`
    ? U
    : P extends `${number}.${infer Rest}`
    ? Rest extends Path<S[number]>
      ? PathValue<U, Rest>
      : never
    : never
  : S extends Payload<any>
  ? S extends UniversalBadRequest | UniversalForbidden | UniversalNotFound
    ? undefined
    : P extends `${infer Key}.${infer Rest}`
    ? Key extends keyof ExtractFound<S>
      ? Rest extends Path<ExtractFound<S>[Key]>
        ? PathValue<ExtractFound<S>[Key], Rest>
        : never
      : never
    : P extends keyof ExtractFound<S>
    ? ExtractFound<S>[P]
    : never
  : P extends `${infer Key}.${infer Rest}`
  ? Key extends keyof S
    ? Rest extends Path<S[Key]>
      ? PathValue<S[Key], Rest>
      : never
    : never
  : P extends keyof S
  ? S[P]
  : never

export type Head<T extends string> = T extends `${infer First}.${string}` ? First : T
export type Tail<T extends string> = T extends `${string}.${infer Rest}` ? Rest : never

export type DeepPick<T, K extends string> = T extends object
  ? {
      [P in Head<K> & keyof T]: T[P] extends readonly unknown[]
        ? DeepPick<T[P][number], Tail<Extract<K, `${P}.${string}`>>>[]
        : DeepPick<T[P], Tail<Extract<K, `${P}.${string}`>>>
    }
  : T

export const arrayOfAll =
  <T>() =>
  <U extends T[]>(array: U & ([T] extends [U[number]] ? unknown : 'Invalid')) =>
    array

export type Nullable<T> = { [K in keyof T]: T[K] | null }
export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined
}

/* -------------------------------------------------------------------------- */
/*                               Concrete Types                               */
/* -------------------------------------------------------------------------- */

/* ---------------------------------- Dates --------------------------------- */

export type DateStringFormat = 'YYYY-MM-DD'
export type DateString =
  `${number}${number}${number}${number}-${number}${number}-${number}${number}`

export type DateTimeStringFormat = 'YYYY-MM-DDTHH:mm:ss'
export type DateTimeString =
  `${number}${number}${number}${number}-${number}${number}-${number}${number}${string}T${number}${number}:${number}${number}:${number}${number}`
export type DateTimeISOString = `${DateTimeString}${
  | 'Z'
  | `${'+' | '-'}${number}${number}-${number}${number}`}`

/* -------------------------------- Requests -------------------------------- */
export type UniversalBadRequest =
  | BadRequest
  | BadRequestFragment
  | { __typename: BadRequest['__typename'] }
export type UniversalForbidden =
  | Forbidden
  | ForbiddenFragment
  | { __typename: Forbidden['__typename'] }
export type UniversalNotFound = NotFound | NotFoundFragment | { __typename: NotFound['__typename'] }

export type ExplicitPayload<TNode> =
  | TNode
  | BadRequestFragment
  | ForbiddenFragment
  | NotFoundFragment

export type Payload<TNode> = TNode | UniversalBadRequest | UniversalForbidden | UniversalNotFound

export type InferTData<T> = T extends TypedDocumentNode<infer TData> ? TData : never
export type InferTVariables<T> = T extends TypedDocumentNode<any, infer TVariables>
  ? TVariables
  : never

/* -------------------------------- Entities -------------------------------- */

export type Entity = ExtractFound<EntityPayload>
export type ConnectedEntity = Extract<EntityPayload, Connected>
export type CustomizableEntity = Extract<EntityPayload, Customizable>
export type DocumentedEntity = Extract<EntityPayload, Documented>
export type MessageGroupedEntity = Extract<EntityPayload, MessageGrouped>
export enum AllMainEntityType {
  Order = 'Order',
  OrderItem = 'OrderItem',
  Shipment = 'Shipment',
  Batch = 'Batch',
  Container = 'Container',
  Product = 'Product',
  ProductProvider = 'ProductProvider',
  ProductProviderPackage = 'ProductProviderPackage',
  Warehouse = 'Warehouse',
}

/* ---------------------------------- Table --------------------------------- */

export type PinnedBy = { [columnKey: string]: boolean }
export enum HighlightColors {
  RED = 'red',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  GREEN = 'green',
  BLUE = 'blue',
  PURPLE = 'purple',
}
export type HighlightBy = { [columnKey: string]: HighlightColors }
export type ColumnWidths = { [columnKey: string]: number }
export type CustomRowColDef = {
  headerName: string
}
export type CustomColDef = ColDef &
  Omit<
    ColumnConfig,
    'sort' | 'pinned' | 'width' | 'key' //? for some reason we named the "field" ColumnDef property as "key"
  >
export interface CustomColGroupDef extends ColGroupDef {
  cellRendererParams: { [k: string]: any }
  colId: string
}

export interface SortsInput {
  field: string
  customFieldId?: Maybe<Scalars['ID']>
  direction: SortOrder
}

/* --------------------------------- Metrics -------------------------------- */

export type MetricNullable = (typeof METRIC_NULLABLES)[number]
export type MetricInvalid = (typeof METRIC_INVALIDS)[number]

export type MetricArea = (typeof METRIC_AREAS)[number]
export type MetricDistance = (typeof METRIC_DISTANCES)[number]
export type MetricDuration = (typeof METRIC_DURATIONS)[number]
export type MetricVolume = (typeof METRIC_VOLUMES)[number]
export type MetricWeight = (typeof METRIC_WEIGHTS)[number]

/** TODO: GraphQL to be updated with metric union */
export type BrandedMetricValue = Omit<MetricValue, 'metric'> & { metric: Metric }
/** TODO: GraphQL to be updated with metric union */
export type CreateBrandedMetricValue<T> = {
  [P in keyof T]: T[P] extends MetricValue | null ? BrandedMetricValue : never
}
/** TODO: GraphQL to be updated with metric union */
export type BrandedMetricValueNullable = CreateMaybe<BrandedMetricValue>
/** TODO: GraphQL to be updated with metric union */
export type CreateBrandedMetricValueNullable<T> = {
  [P in keyof T]: T[P] extends MetricValue | null ? BrandedMetricValueNullable : never
}

/** TODO: GraphQL to be updated with metric union */
export type BrandedMetricInput = Omit<MetricValueInput, 'metric'> & { metric: Metric }
/** TODO: GraphQL to be updated with metric union */
export type CreateBrandedMetricInput<T> = {
  [P in keyof T]: T[P] extends MetricValue | null ? BrandedMetricInput : never
}
/** TODO: GraphQL to be updated with metric union */
export type BrandedMetricInputNullable = CreateMaybe<BrandedMetricInput>

/** TODO: GraphQL to be updated with metric union */
export type Metric = MetricArea | MetricDistance | MetricDuration | MetricWeight | MetricVolume
/** TODO: GraphQL to be updated with metric union */
export type ServerSideMetricWeight = (typeof METRIC_WEIGHTS)[number] | string
/** TODO: GraphQL to be updated with metric union */
export type ServerSideMetricLength = (typeof METRIC_DISTANCES)[number] | string
/** TODO: GraphQL to be updated with metric union */
export type ServerSideMetricVolume = (typeof METRIC_VOLUMES)[number] | string
/** TODO: GraphQL to be updated with metric union */
export type ServerSideMetricWeightsComp = (typeof METRIC_WEIGHTS_COMP)[number] | string

/* ------------------------------- Connections ------------------------------ */

export type ConnectionColor = (typeof CONNECTION_COLORS)[number]
